import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

const promoCookie = getCookie('yzpdc')

// Gods, this is a mess. Please forgive me.
if (promoCookie) {
  const { code, endTime } = JSON.parse(promoCookie)
  const closeBtn = document.querySelector('.promo-banner__close-button')

  let promoBannerActive = true
  let promoScreenOffset = 0

  if (window.screen.width < 700) {
    promoScreenOffset = 20
  }

  if (getCookie('yzhpb')) {
    if (getCookie('yzhpb') === 'true') {
      promoBannerActive = false
    }
  }

  // Pulling in style modifications from the Announcement Promo Banner
  $(function () {
    if (getCookie('yzpdc') && promoBannerActive) {
      const $banner = $('.promo-banner')
      let $promoheight = $banner.height() + promoScreenOffset
      $('.main').css('margin-top', $promoheight)
      $('.nav--hamburger__top').css('margin-top', $promoheight)
      $('.js-menu-1').css('margin-top', $promoheight)
      $banner.slideDown()
      $('body').addClass('promo-banner-active')
    }
  })
  // Close button
  closeBtn?.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('.promo-banner')?.remove()
    setCookie('yzhpb', 'true', 0)
    const $banner = $('.promo-banner')
    $banner.slideUp()
    $('body').removeClass('promo-banner-active')
    $('.main').css('margin-top', '0px')
    $('.nav--hamburger__top').css('margin-top', '0px')
    $('.js-menu-1').css('margin-top', '0px')
  })

  // Countdown
  function updateCountdown() {
    const now = new Date()
    const endDate = new Date(endTime * 1000)
    const distance = endDate - now

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
    const dayEl = document.querySelector('.promo-banner__days')
    const hourEl = document.querySelector('.promo-banner__hours')
    const minEl = document.querySelector('.promo-banner__minutes')
    const secEl = document.querySelector('.promo-banner__seconds')

    dayEl.innerHTML = days
    hourEl.innerHTML = hours
    minEl.innerHTML = minutes
    secEl.innerHTML = seconds
  }

  updateCountdown()
  setInterval(updateCountdown, 1000)

  // Add legal text to footer
  const disclaimerBlock = document.querySelector('.disclaimerBlock')
  const legalText =
    "<p>*Limited to new packages purchased 7 days after signup. Must enter unique redemption code<br>at checkout to receive 30% off design package purchase. Cannot be combined with other promotions.<br>Not valid on My Lowe's Rewards, Starter Packages, Botanical Packages, or gift cards.</p>"
  disclaimerBlock.innerHTML = legalText

  // Add discount "placements"
  const disclaimers = document.querySelectorAll(
    '.packages__price,.pkghero__discount-message'
  )
  const discountText = `<i>Limited time offer: Enjoy 30% off with code <strong style="text-decoration: underline;">${code}</strong></i>`
  if (
    window.location.pathname === '/front-yard' ||
    window.location.pathname === '/back-yard' ||
    window.location.pathname === '/full-yard' ||
    window.location.pathname === '/outdoor-transformation' ||
    window.location.pathname === '/premium'
  ) {
    for (const disclaimer of Array.from(disclaimers)) {
      if (disclaimer.classList.contains('packages__price')) {
        const el = document.createElement('span')
        el.innerHTML = discountText
        el.style =
          'display: block; margin-top: 1rem; font-size: 1rem; font-weight: normal;'
        disclaimer.appendChild(el)
      } else {
        disclaimer.innerHTML = discountText
      }
    }
  }
}
