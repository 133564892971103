import $ from 'jquery'
// import screenfull from 'screenfull'
import magnificPopup from 'magnific-popup'
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

$(document).ready( initBeforeAfter )
if( window.acf ){
  window.acf.addAction( 'render_block_preview/type=before-after', initBeforeAfter )
}

function initBeforeAfter(){
    $( ".before-after__slider" ).each( function( i ) {

        const activeElem = $(this)
        // console.log(activeElem[0])

        function getSliderSizes(element) {
            const sliderPos = element.children('.before-after__slider').val();
            const sliderMin = 0;
            const sliderMax = 100;
            const sliderWidth = element.innerWidth()
            const forgWidth = (((sliderPos / 100) * ((sliderWidth - 4) - 4)) + 8)   // foreground image width
            const borderWidth = $('.before-after__img--foreground')
              ?.css('border-right-width')
              ?.replace('px', '');
            const borderOffset = parseInt(borderWidth) === 8 ? 4 : 6;
            const leftBtn = (((sliderPos / 100) * ((sliderWidth - 4) - 4)) + borderOffset) - 21   // left button position
            element.children('.before-after__img--foreground').css('width', `${forgWidth}px`)
            element.children('.before-after__img--foreground').children('.before-after__img--foreground-inner').css('width', `${sliderWidth}px`)
            element.children('.before-after__button').css('left', `${leftBtn}px`);
        }

        function openModal( target ) {
            $.magnificPopup.open({
                items: {
                    src: target,
                    type: 'inline',
                },
                callbacks: {
                    open: function() {
                        getSliderSizes(activeElem.parent());
                        activeElem.parent().addClass('is-active')
                        $('#intercom-container').css('display', 'none')
                        $('#intercom-frame').css('display', 'none')
                        // disableBodyScroll(activeElem.parent().parent());
                        $(activeElem).focus()
                    },
                    close: function(){
                        const url = new URL( window.location )
                        url.hash = ''
                        history.pushState( {}, '', url )
                        activeElem.parent().removeClass('is-active')
                        $('#intercom-container').css('display', 'block')
                        // enableBodyScroll(activeElem.parent().parent());
                    }
                },
                closeBtnInside: true
            });
        }

        activeElem.on('input change', (event)=>{
            getSliderSizes(activeElem.parent());
        });

        $(window).on('load resize', (event)=>{

            getSliderSizes(activeElem.parent());
            // console.log('This was resize')

            // @todo: Remove !$('.homepage-redux')
            if(window.innerWidth < 960 && !$('.homepage-redux')) {
                if (!$('.js-beforeafter-mobile-content').hasClass('mfp-hide') && !$('.js-beforeafter-mobile-content').hasClass('is-active')) {
                    $('.js-beforeafter-mobile-content').addClass('mfp-hide')
                }
                $(document).on('click', '.js-beforeafter-mobile-button', e => {
                    openModal( e.target.hash )
                } );

                // activeElem.parent().addClass('popup popup--animate')

            } else {
                $('.js-beforeafter-mobile-content').removeClass('mfp-hide')
                getSliderSizes(activeElem.parent());
            }
        })
    })
}
