const endDate = new Date(Date.UTC(2023, 10, 20, 8, 0, 0))
const baseCountdownClass = "bfcd-counter-value-"
const daySelector = `${baseCountdownClass}days`
const hourSelector = `${baseCountdownClass}hours`
const minuteSelector = `${baseCountdownClass}minutes`
const secondSelector = `${baseCountdownClass}seconds`

const updateCountdown = () => {
  const now = new Date()
  const distance = endDate - now

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const dayElements = document.getElementsByClassName(daySelector)
  const hourElements = document.getElementsByClassName(hourSelector)
  const minuteElements = document.getElementsByClassName(minuteSelector)
  const secondElements = document.getElementsByClassName(secondSelector)

  if (dayElements.length > 0) {
    for (let i = 0; i < dayElements.length; i++) {
      dayElements[i].innerHTML = days
    }
  }

  if (hourElements.length > 0) {
    for (let i = 0; i < hourElements.length; i++) {
      hourElements[i].innerHTML = hours
    }
  }

  if (minuteElements.length > 0) {
    for (let i = 0; i < minuteElements.length; i++) {
      minuteElements[i].innerHTML = minutes
    }
  }

  if (secondElements.length > 0) {
    for (let i = 0; i < secondElements.length; i++) {
      secondElements[i].innerHTML = seconds
    }
  }
}

// Initialize the values so there is not a 1 second delay
updateCountdown();

setInterval(updateCountdown, 1000)
